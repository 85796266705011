import { useState } from "react"
import toast from "react-hot-toast";
import { useLoginAdminContext } from "../../hooks/LoginContext";

export default function LoginIndex() {

    const [loginInp, setloginInp] = useState({
        email: '',
        password: '',
    })
    const [loading, setloading] = useState<boolean>(false)

    const {setAdminToken} = useLoginAdminContext();

    const loginHandle = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setloading(true);
        await fetch(`${process.env.REACT_APP_NODE_URL}/admin/login`, {
            method: 'POST',
            cache: 'no-cache',
            body: JSON.stringify(loginInp),
            headers: { 
                'Content-Type': 'application/json',
                
             },
        }).then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    toast.success(res.message);
                    setAdminToken(res.result);
                    window.location.href = '/dashboard'
                }
                else if (res.status === 400) {
                    toast.error(res.message);
                }
                else if (res.status === 500) {
                    toast.error(res.message);
                }
                setloading(false);
            })
            .catch(err => {
                console.log(err);
            })

    }
    return (
        <div className="w-full min-h-[100dvh] flex items-center justify-center">
            <div className="w-[600px] border border-gray-400 p-8 rounded-lg bg-gray-900">
                <div className="heading-part">
                    <h2 className="text-2xl font-bold text-center">Welcome Back</h2>
                </div>
                <div className="form-part">
                    {
                        loading ? 'Loading . . . ' :

                            <form onSubmit={loginHandle}>
                                <div className="mb-5 input-outer">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                    <input type="email" className="form-control" onChange={(e) => setloginInp({ ...loginInp, email: e.target.value })} />
                                </div>
                                <div className="mb-5">
                                    <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                    <input type="password" className="form-control" onChange={(e) => setloginInp({ ...loginInp, password: e.target.value })} />
                                </div>

                                <button type="submit" className="button"><span>Submit</span></button>
                            </form>
                    }
                </div>
            </div>
        </div>
    )
}
