import { useEffect, useState, useMemo } from "react";
import toast from 'react-hot-toast';
import CommonTable from "../../../component/table/CommonTable";
import { useLoginAdminContext } from "../../../hooks/LoginContext";
import moment from "moment";
import { Link } from "react-router-dom";

export default function SerialNoList() {
  const [catetableData, setcatetableData] = useState([]);
  const [loading, setloading] = useState(false);

  const { adminToken } = useLoginAdminContext();

  useEffect(() => {
    const fetchCateTableData = async () => {
      setloading(true);
      await fetch(`${process.env.REACT_APP_NODE_URL}/product-serial/list`, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
          'Authorization': `Bearer ${adminToken}`
        }
      }).then(res => res.json())
        .then(res => {
          if (res.status === 200) {
            toast.success(res.message);
            setcatetableData(res.result);
          }
          else if (res.status === 400) {
            toast.error(res.message);
          }
          else if (res.status === 500) {
            toast.error(res.message);
          }
          setloading(false);
        })
        .catch(err => {
          console.log(err);
        })
    }
    fetchCateTableData();
  }, [adminToken])

  const data = useMemo(() => catetableData, [catetableData])

  /** @type import('@tanstack/react-table').ColumnDef<any> */
  const columns = [
    {
      header: 'ID',
      accessorFn: (row, index) => index + 1,
    },
    {
      header: 'Serial No',
      accessorKey: 'serialNumber',
    },
    {
      header: 'Model No',
      accessorKey: 'modalno',
    },
    {
      header: 'Dispatch Date',
      accessorFn: (row, index) => moment(row.dispatchdate).format('L'),
    },
    {
      header: 'Dispatch From',
      accessorKey: 'dispatchfrom',
    },
    {
      header: 'Dispatch To',
      accessorKey: 'dispatchto',
    },
    {
      header: 'Dealer Code',
      accessorKey: 'dealerCode',
    },
    {
      header: 'Invoice No',
      accessorKey: 'invoiceno',
    },
    {
      header: 'utilize',
      cell: cell =>
      (
        <span className={`${cell.row.original.utilize ? "bg-green-300 text-green-700" : "bg-red-300 text-red-700"}  py-1 px-1.5 rounded-lg font-semibold`}>{cell.row.original.utilize ? 'True' : 'False'}</span>
      )
    }
  ]


  return (
    <>
      <div className="page-head flex items-center justify-between">
        <h2 className="text-3xl font-semibold text-slate-100 text-center mb-2">Product Serial Number List</h2>
        <Link to={'/dashboard/serial-add'}><button className="bg-slate-400 text-slate-950 font-semibold flex items-center justify-center h-[40px] min-w-[140px] rounded-lg">Add New</button></Link>
      </div>
      <div>
        {loading ? 'loading...' : <CommonTable data={data} columns={columns} loading={loading} />}
      </div>
    </>
  )
}
