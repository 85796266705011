import { jwtDecode } from "jwt-decode";
import { ReactNode, createContext, useContext, useEffect, useState } from "react";


type LoginProviderProps = {
    children: ReactNode
}

type LoginContext = {
    setAdminToken: (id: string) => void
    adminToken: string
    checkProtectRoute: () => boolean
}
const LoginAdminContext = createContext({} as LoginContext)

export function useLoginAdminContext() {
    return useContext(LoginAdminContext)
}


export default function LoginContext({ children }: LoginProviderProps) {

    const [adminToken, setadminToken] = useState<string>('')

    useEffect(() => {
        const fetchAdminToken = () => {
            const adminTokenData = window.localStorage.getItem('adminToken');
            if (adminTokenData) {
                setadminToken(adminTokenData)
            }
        }
        fetchAdminToken();
    }, [])



    const setAdminToken = (token: string) => {
        window.localStorage.setItem('adminToken', token)
        setadminToken(token)
    }

    const checkProtectRoute = () => {
        const adminTokenData = window.localStorage.getItem('adminToken');
        if (!adminTokenData) {
            return false;
        }
        const decodeToken = jwtDecode(adminTokenData)
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodeToken?.exp && decodeToken.exp < currentTime) return false;

        return true;

    }

    return (
        <LoginAdminContext.Provider value={{
            setAdminToken, adminToken, checkProtectRoute
        }}>
            {children}
        </LoginAdminContext.Provider>
    )
}
