import React, { useState } from 'react'
import { useLoginAdminContext } from '../../../hooks/LoginContext';
import toast from 'react-hot-toast';

export default function SerialImport() {

    const { adminToken } = useLoginAdminContext();
    const [loading, setloading] = useState<boolean>(false)
    const [file, setfile] = useState<File | null>(null)


    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setloading(true);
        const selectedFile = e.target.files?.[0];
        console.log(selectedFile)
        if (selectedFile) {
            setfile(selectedFile);
            const formdata = new FormData;
            formdata.append('file', selectedFile);
            await fetch(`${process.env.REACT_APP_NODE_URL}/product-serial/sheet-add`, {
                method: 'POST',
                cache: 'no-cache',
                body: formdata,
                headers: {
                    'Authorization': `Bearer ${adminToken}`
                },
            }).then(res => res.json())
                .then(res => {
                    console.log(res)
                    if (res.status === 200) {
                        toast.success(res.message);
                    }
                    else if (res.status === 400) {
                        toast.error(res.message);
                    }
                    else {
                        toast.error(res.message);
                    }
                    setloading(false);
                })
                .catch(err => {
                    console.log(err);
                })
        }
    };

    return (
        <section className="">

            <div className="max-w-xl upload-part flex flex-col gap-3  mx-auto p-10 border border-slate-500 rounded-lg mb-4">

                {
                    loading ? 'Loading ...' :
                        <>
                            <div className='flex items-center gap-2'>
                                <div className='mb-1.5 font-semibold'>Download Sample Sheet : </div>
                                <a href="../../../assets/sample-sheet.xlsx" download="sample-sheet.xlsx" className="bg-slate-400 text-slate-950 font-semibold flex items-center justify-center h-[40px] min-w-[140px] rounded-lg">Download</a>
                            </div>

                            <div>
                                <div className='mb-1.5 font-semibold'>Upload Sheet : </div>
                                <label htmlFor="sheetupload" className='sheetupload flex items-center justify-center'>
                                    <div className="icon flex items-center justify-center flex-col">
                                        <svg className="w-[30px] h-[30px] mb-1" width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">

                                            <g id="SVGRepo_bgCarrier" strokeWidth="0" />

                                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />

                                            <g id="SVGRepo_iconCarrier"> <path d="M12 15L12 2M12 2L15 5.5M12 2L9 5.5" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /> <path d="M8 22.0002H16C18.8284 22.0002 20.2426 22.0002 21.1213 21.1215C22 20.2429 22 18.8286 22 16.0002V15.0002C22 12.1718 22 10.7576 21.1213 9.8789C20.3529 9.11051 19.175 9.01406 17 9.00195M7 9.00195C4.82497 9.01406 3.64706 9.11051 2.87868 9.87889C2 10.7576 2 12.1718 2 15.0002L2 16.0002C2 18.8286 2 20.2429 2.87868 21.1215C3.17848 21.4213 3.54062 21.6188 4 21.749" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" /> </g>

                                        </svg>
                                        <p className='text-black font-semibold'>Upload File</p>
                                    </div>
                                    <input type="file" onChange={handleFileChange} name="sheetupload" id="sheetupload" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                                </label>
                            </div>
                        </>

                }


            </div>
        </section>
    )
}
