import { Link, Outlet, useLocation } from 'react-router-dom'
import { FaSquareCaretRight } from "react-icons/fa6";

const DashnavList = [
    { name: "Dashboard", link: '/dashboard' },
    { name: "Serial No. List", link: '/dashboard/serial-list' },
    { name: "Request List", link: '/dashboard/request-list' }
]

const logoutHandle =async () => {

    await fetch(`${process.env.REACT_APP_NODE_URL}/admin/logout`, {
        method: 'GET',
        cache: 'no-cache',
    }).then(res => res.json())
        .then(res => {
            console.log(res)
            if (res.status === 200) {
                window.localStorage.clear();
                window.location.href = '/'
            }
            else if (res.status === 400) {
                window.location.href = '/'
            }
            else if (res.status === 500) {
                window.location.href = '/'
            }
        })
        .catch(err => {
            console.log(err);
        })
}

export default function DashboardLayout() {

    const location = useLocation();
    return (
        <section className='w-full h-[100dvh]'>
            <aside className='border-r border-gray-600  w-[300px] h-[100dvh] fixed'>
                <div className="dashnav p-2">
                    <div className="head-part text-2xl font-semibold uppercase text-slate-50 px-1.5 py-4 text-center border-b border-slate-500">
                        Bajaj Admin
                    </div>
                    <ul className='flex flex-col gap-2 p-1'>
                        {
                            DashnavList.map((item, index) => (
                                <li>
                                    <Link key={index} to={item.link}
                                        className={`text-slate-200 px-4 py-2 rounded-lg font-medium flex items-center gap-4 ${
                                            (location.pathname === item.link) ? 'bg-slate-700' : null
                                        }`}
                                    ><FaSquareCaretRight /> {item.name}</Link>
                                </li>
                            ))
                        }
                        <li  className='text-slate-200 px-4 py-2 rounded-lg font-medium flex items-center gap-4 cursor-pointer' onClick={logoutHandle}>
                        <FaSquareCaretRight /> Logout
                        </li>
                    </ul>
                </div>
            </aside>
            <main className='dashboard-right-part '>
                <Outlet />
            </main>
        </section>
    )
}
