import { useState } from "react"
import { useLoginAdminContext } from "../../../hooks/LoginContext";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";

export default function SerialAdd() {

    const [addSerialInp, setaddSerialInp] = useState({
        serialNumber: '',
        modalno: '',
        dispatchfrom: '',
        invoiceno: '',
        dispatchdate: 0,
        dispatchto: '',
        dealerCode: '',
    })
    const [loading, setloading] = useState<boolean>(false)
    const [file, setfile] = useState<FileList | null>(null)

    const { adminToken } = useLoginAdminContext();

    const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        console.log(addSerialInp)

        setloading(true);
        await fetch(`${process.env.REACT_APP_NODE_URL}/product-serial/add`, {
            method: 'POST',
            cache: 'no-cache',
            body: JSON.stringify(addSerialInp),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${adminToken}`
            },
        }).then(res => res.json())
            .then(res => {
                console.log(res)
                if (res.status === 200) {
                    toast.success(res.message);
                }
                else if (res.status === 400) {
                    toast.error(res.message);
                }
                else {
                    toast.error(res.message);
                }
                setloading(false);
            })
            .catch(err => {
                console.log(err);
            })

    }

 

    return (
        <section className="">
            <div className="max-w-2xl mx-auto p-10 border border-slate-500 rounded-lg">
                <div className="heading mb-4 flex items-center justify-between">
                    <h2 className="text-2xl font-semibold text-center text-slate-100">Add Product Serial No </h2>
                    <Link to={'/dashboard/serial-add/import'}>
                        <button className="bg-slate-400 text-slate-950 font-semibold flex items-center justify-center h-[40px] min-w-[140px] rounded-lg">Import All</button>
                    </Link>
                </div>

                <div>{
                    loading ? 'Loading....' :
                        <form onSubmit={onSubmit} className="form">
                            <div className="mb-4 form-inp-div">
                                <label htmlFor="serialNumber">Serial Number</label>
                                <input type="text" name="serialNumber" id="serialNumber" onChange={(e) => setaddSerialInp({ ...addSerialInp, serialNumber: e.target.value })} />
                            </div>
                            <div className="mb-4 form-inp-div">
                                <label htmlFor="modalno">Modal</label>
                                <input type="text" name="modalno" id="modalno" onChange={(e) => setaddSerialInp({ ...addSerialInp, modalno: e.target.value })} />
                            </div>
                            <div className="mb-4 form-inp-div">
                                <label htmlFor="dealerCode">Dealer Code</label>
                                <input type="text" name="dealerCode" id="dealerCode" onChange={(e) => setaddSerialInp({ ...addSerialInp, dealerCode: e.target.value })} />
                            </div>
                            <div className="mb-4 form-inp-div">
                                <label htmlFor="dispatchdate">dispatch date</label>
                                <input type="date" name="dispatchdate" id="dispatchdate" onChange={(e) => setaddSerialInp({ ...addSerialInp, dispatchdate: new Date(e.target.value).getTime() })} />
                            </div>
                            <div className="mb-4 form-inp-div">
                                <label htmlFor="dispatchfrom">dispatch from</label>
                                <input type="text" name="dispatchfrom" id="dispatchfrom" onChange={(e) => setaddSerialInp({ ...addSerialInp, dispatchfrom: e.target.value })} />
                            </div>
                            <div className="mb-4 form-inp-div">
                                <label htmlFor="dispatchto">dispatch to</label>
                                <input type="text" name="dispatchto" id="dispatchto" onChange={(e) => setaddSerialInp({ ...addSerialInp, dispatchto: e.target.value })} />
                            </div>
                            <div className="mb-4 form-inp-div">
                                <label htmlFor="invoiceno">invoice no</label>
                                <input type="text" name="invoiceno" id="invoiceno" onChange={(e) => setaddSerialInp({ ...addSerialInp, invoiceno: e.target.value })} />
                            </div>

                            <button className="bg-slate-400 py-2 px-4 rounded-md text-slate-950 font-semibold" type="submit">Submit</button>
                        </form>
                }
                </div>
            </div>

        </section>
    )
}
