import { Toaster } from 'react-hot-toast';
import './App.css';
import DashboardLayout from './pages/Dashboard/layout';

import LoginIndex from './pages/login';
import { Navigate, Route, Routes } from 'react-router-dom';
import SerialNoList from './pages/Dashboard/serialnoList';
import ProtectedRoute from './component/ProtectedRoute/ProtectedRoute';
import SerialAdd from './pages/Dashboard/serialAdd';
import SerialImport from './pages/Dashboard/serialImport';
import RequestList from './pages/Dashboard/request-list';

function App() {
  return (
    <>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
      />
      <Routes>
        <Route path='/' element={<LoginIndex />} />
        <Route path='/dashboard' element={<DashboardLayout />} >

          <Route path="/dashboard/serial-list" element={<ProtectedRoute><SerialNoList /></ProtectedRoute>} />
          <Route path="/dashboard/request-list" element={<ProtectedRoute><RequestList /></ProtectedRoute>} />
          <Route path="/dashboard/serial-add" element={<ProtectedRoute><SerialAdd /></ProtectedRoute>} />
          <Route path="/dashboard/serial-add/import" element={<ProtectedRoute><SerialImport /></ProtectedRoute>} />

          <Route path="/dashboard" element={<Navigate to="/dashboard/serial-list" />} />

        </Route>

        {/* <Route path='*' element={<Error />} /> */}
      </Routes>
    </>
  );
}

export default App;
